import burger from "./modules/burger";
import FixedElementClearance from "./modules/FixedElementClearance";
import LazyLoad from "vanilla-lazyload";
import MenuToggle from "./modules/MenuToggle";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll";
import chat from "./modules/chat";
import "lightbox2";
import LoadMore from "./modules/load_more";

// import "jquery-zoom";
// Less used modules
//
import accordion from "./modules/accordion";
import DragonModal from "./modules/DragonModal";
import DragonTabs from "./modules/DragonTabs";
import slides from "./modules/slides";


export default () => {
    burger();
    chat();
    LoadMore();

    new LazyLoad({ elements_selector: ".lazy" });

    new SmoothScroll('a[href*="#"]', {
        header: ".main-header-bundle",
        speed: 1500,
        speedAsDuration: true,
        easing: "easeOutQuint",
    });

    new FixedElementClearance({
        element: ".js-get-main-header-height",
        CSSVariableName: "main-header-height",
    });

    new MenuToggle({
        menuToggle: [".js-main-menu-mobile-toggle"],
        menuElement: ".main-menu-mobile",
        menuClose: [".js-close-menu-mobile"],
        activeBodyClass: "mobile-menu-is-open",
        breakpointToHide: 1200,
    });

    // Less used modules
    //
    accordion();
    new DragonModal();
    new DragonTabs();

    slides();



    // $(document).ready(function(){
    //     $('div.photoZoom').zoom({
    //         url: 'photo-big.jpg', 
    //         callback: function(){
    //         $(this).colorbox({href: this.src});
    //         }
    //     });
    // });

    // $('div.photoZoom').zoom({ on:'toggle',magnify: 1 });
    // $('div.plant-image').zoom({ on:'toggle',magnify: 1 });
    
};
