

/**
 * Load More
 *
 * @author Index Digital
 */

export default function LoadMore() {
    jQuery(function($){ // use jQuery code inside this to avoid "$ is not defined" error
      let page_todos = 1;
      let page_comercial = 1;
      let page_reside = 1;
      let pass_page = 1;
      let max_page = 1;
      let div_insert = null;

      let type = 'all';
        $('.port_loadmore').click(function(){

            type = $(this).data('type');
            max_page = $(this).data('total');

            switch (type) {
                case 'all':
                    page_todos = page_todos + 1;
                    pass_page = page_todos;
                    div_insert = $('#all');
                    break;

                case 'residenciais':
                    page_reside = page_reside + 1;
                    pass_page = page_reside;
                    div_insert = $('#residenciais');
                    break;

                case 'comerciais':
                    page_comercial = page_comercial + 1;
                    pass_page = page_comercial;
                    div_insert = $('#comerciais');
                    break;
            
                default:
                    break;
            }

            var button = $(this),
                data = {
                'action': 'loadmore',
                'type': type, // that's how we get params from wp_localize_script() function
                'page' : pass_page
            };

            $.ajax({ // you can also use $.post here
                url : port_loadmore_params.ajaxurl, // AJAX handler
                data : data,
                type : 'POST',
                beforeSend : function ( xhr ) {
                    button.text('Carregando...'); // change the button text, you can also add a preloader image
                },
                success : function( data ){
                    //console.log(port_loadmore_params.max_page );
                    if( data ) { 
                        button.text( 'Ver mais' ); // insert new posts
                       
                        $(div_insert).append(data);
                       
                        
                        if ( pass_page == max_page ) 
                            button.remove(); // if last page, remove the button

                        // you can also fire the "post-load" event here if you use a plugin that requires it
                        // $( document.body ).trigger( 'post-load' );
                    } else {
                        button.remove(); // if no data, remove the button as well
                    }
                }
            });
        });
    });
}

