

/**
 * Chat Controller
 *
 * @author Ricardo Silva 
 */

export default function chat() {
    const openArea = document.querySelector(".chat-area .open");
    const closeArea = document.querySelector(".chat-area .close");
    const modalArea = document.querySelector(".contact-top-area");

    openArea.addEventListener("click", (e) => {
        openChatArea();
    })

    closeArea.addEventListener("click", (e) => {
        closeChatArea();
    })

    function openChatArea() {
        modalArea.classList.add("is-active");
        closeArea.classList.remove("is-hidden");
        openArea.classList.add("is-hidden");
    }

    function closeChatArea() {
        modalArea.classList.remove("is-active");
        closeArea.classList.add("is-hidden");
        openArea.classList.remove("is-hidden");
    }
}
